exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-ausstellungen-tsx": () => import("./../../../src/pages/ausstellungen.tsx" /* webpackChunkName: "component---src-pages-ausstellungen-tsx" */),
  "component---src-pages-biografie-tsx": () => import("./../../../src/pages/biografie.tsx" /* webpackChunkName: "component---src-pages-biografie-tsx" */),
  "component---src-pages-datenschutz-tsx": () => import("./../../../src/pages/datenschutz.tsx" /* webpackChunkName: "component---src-pages-datenschutz-tsx" */),
  "component---src-pages-impressum-tsx": () => import("./../../../src/pages/impressum.tsx" /* webpackChunkName: "component---src-pages-impressum-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-kunst-tsx": () => import("./../../../src/pages/kunst.tsx" /* webpackChunkName: "component---src-pages-kunst-tsx" */),
  "component---src-pages-links-tsx": () => import("./../../../src/pages/links.tsx" /* webpackChunkName: "component---src-pages-links-tsx" */),
  "component---src-pages-literatur-tsx": () => import("./../../../src/pages/literatur.tsx" /* webpackChunkName: "component---src-pages-literatur-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-videos-tsx": () => import("./../../../src/pages/videos.tsx" /* webpackChunkName: "component---src-pages-videos-tsx" */)
}

